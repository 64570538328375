import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    username: '',
    isLabel: true,
    isTitle: false,
    role: 'ADMIN, SEO, DEO, PARENT, PRINCIPAL, PEO, DISTRICT_ELECTORAL_TEAM, SGB_ASSOCIATION, INTER_PROVINCIAL_TASK_TEAM, SCHOOL_TASK_TEAM, PROVINCIAL_MONITOR, OBSERVER, PEM, DISTRICT_MONITOR, DISTRICT_OBS, DISTRICT_DIRECTOR, SGB, HO, SGB_MEMBER, HOD, DO, OFFICE_BEARER, DISTRICT_USER, SGB_SECRETARY, SGB_TREASURER, TEACHER, LEARNER, SGB_CHAIRPERSON, MGSLG,' +
      'Educator_Representative, PS_Staff_Representative, RCL_Representative, Organisations_for_LSEN_schools, PS, CS, RCL, SMT '
  },
  {
    label: 'HOME',
    icon: 'home',
    link: '/dashboard',
    isHome: true,
    role: 'ADMIN, SEO, DEO, PARENT, PRINCIPAL, PEO, DISTRICT_ELECTORAL_TEAM, SGB_ASSOCIATION, INTER_PROVINCIAL_TASK_TEAM, SCHOOL_TASK_TEAM, PROVINCIAL_MONITOR, OBSERVER, PEM, DISTRICT_MONITOR, DISTRICT_OBS, DISTRICT_DIRECTOR, SGB, HO, SGB_MEMBER, HOD, DO, OFFICE_BEARER, DISTRICT_USER, SGB_SECRETARY, SGB_TREASURER, TEACHER, LEARNER, SGB_CHAIRPERSON, MGSLG' +
      'Educator_Representative, PS_Staff_Representative, RCL_Representative, Organisations_for_LSEN_schools, PS, CS, RCL, SMT '
  },
  {
    label: 'SGB',
    icon: 'user-check',
    role: 'SGB_MEMBER',
    subItems: [
      {
        label: 'Election',
        link: '/election/voting',
        role: '',
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: '',
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      // {
      //   label: 'Meetings',
      //   link: '/meeting/meeting-invitation',
      //   role: ''
      // },
      // {
      //   label: 'Outgoing/Incoming checklist',
      //   link: '/handover/new',
      //   role: '',
      // },
      // {
      //   label: 'Nomination',
      //   link: '/nominations/nominate',
      //   role: ''
      // },
      // {
      //   label: 'Nomination List',
      //   link: '/nominations/nomination-list',
      //   role: ''
      // },
      // {
      //   label: 'School Handover',
      //   link: '/handover/upload',   HIDE
      //   role: ''
      // },
      {
        label: 'SGB Handover',
        link: '/handover/new',
        role: ''
      },
      // {
      //   label: 'Training Schedule',
      //   link: '/course/scheduled-training',
      //   role: ''
      // },
      // {
      //   label: 'View Disputes',
      //   link: '/disputes/list',
      //   role: ''
      // },
      // {
      //   label: 'View Election Results',
      //   link: '/election/results',
      //   role: 'SGB',
      // },
      // {
      //   label: 'Election',
      //   link: '/election/voting',
      //   role: '',
      // }

    ]
  },
  {
    label: 'SGB Secretary',
    icon: 'user-check',
    role: 'SGB_SECRETARY',
    subItems: [
      {
        label: 'Election',
        link: '/election/voting',
        role: '',
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: '',
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      // {
      //   label: 'Meetings',
      //   link: '/meeting/meeting-invitation',
      //   role: ''
      // },
      // {
      //   label: 'Nomination',
      //   link: '/nominations/nominate',
      //   role: ''
      // },
      // {
      //   label: 'Nomination List',
      //   link: '/nominations/nomination-list',
      //   role: ''
      // },
      // {
      //   label: 'School Handover',
      //   link: '/handover/upload',
      //   role: ''
      // },
      {
        label: 'SGB Handover',
        link: '/handover/new',
        role: ''
      },
      // {
      //   label: 'Training Schedule',
      //   link: '/course/scheduled-training',
      //   role: ''
      // },
      // {
      //   label: 'View Disputes',
      //   link: '/disputes/list',
      //   role: ''
      // },
      // {
      //   label: 'View Election Results',
      //   link: '/election/results',
      //   role: 'SGB',
      // },
      // {
      //   label: 'Election',
      //   link: '/election/voting',
      //   role: '',
      // }

    ]
  },
  {
    label: 'SGB Treasurer',
    icon: 'user-check',
    role: 'SGB_TREASURER',
    subItems: [
      {
        label: 'Election',
        link: '/election/voting',
        role: '',
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: '',
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      // {
      //   label: 'Meetings',
      //   link: '/meeting/meeting-invitation',
      //   role: ''
      // },
      // {
      //   label: 'Nomination',
      //   link: '/nominations/nominate',
      //   role: ''
      // },
      // {
      //   label: 'Nomination List',
      //   link: '/nominations/nomination-list',
      //   role: ''
      // },
      // {
      //   label: 'School Handover',
      //   link: '/handover/upload',
      //   role: ''
      // },
      {
        label: 'SGB Handover',
        link: '/handover/new',
        role: ''
      },
      // {
      //   label: 'Training Schedule',
      //   link: '/course/scheduled-training',
      //   role: ''
      // },
      // {
      //   label: 'View Disputes',
      //   link: '/disputes/list',
      //   role: ''
      // },
      // {
      //   label: 'View Election Results',
      //   link: '/election/results',
      //   role: 'SGB',
      // },
      // {
      //   label: 'Election',
      //   link: '/election/voting',
      //   role: '',
      // }

    ]
  },
  {
    label: 'MGSLG',
    icon: 'user-check',
    role: 'MGSLG',
    subItems: [
      {
        label: 'Election Results',
        link: '/election/results',
        role: '',
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Meetings',
        link: '/meeting/meeting-invitation',
        role: ''
      },
      {
        label: 'SGB Handover',
        link: '/handover/new',
        role: ''
      },
      // {
      //   label: 'Training Schedule',
      //   link: '/course/scheduled-training',
      //   role: ''
      // },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }

    ]
  },
  {
    label: 'SGB Chairperson',
    icon: 'user-check',
    role: 'SGB_CHAIRPERSON',
    subItems: [
      {
        label: 'Election',
        link: '/election/voting',
        role: '',
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: '',
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'SGB Handover',
        link: '/handover/new',
        role: ''
      }

    ]
  },
  // ADDITIONAL ROLES
  {
    label: 'SMT',
    icon: 'user-check',
    role: 'SMT',
    subItems: [
      {
        label: 'Election',
        link: '/election/voting',
        role: '',
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: '',
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'SGB Handover',
        link: '/handover/new',
        role: ''
      }

    ]
  },
  {
    label: 'CS',
    icon: 'user-check',
    role: 'CS',
    subItems: [
      {
        label: 'Election',
        link: '/election/voting',
        role: '',
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: '',
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'SGB Handover',
        link: '/handover/new',
        role: ''
      }

    ]
  },
  {
    label: 'RCL',
    icon: 'user-check',
    role: 'RCL',
    subItems: [
      {
        label: 'Election',
        link: '/election/voting',
        role: '',
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: '',
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'SGB Handover',
        link: '/handover/new',
        role: ''
      }

    ]
  },
  {
    label: 'PS',
    icon: 'user-check',
    role: 'PS',
    subItems: [
      {
        label: 'Election',
        link: '/election/voting',
        role: '',
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: '',
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'SGB Handover',
        link: '/handover/new',
        role: ''
      }

    ]
  },
  {
    label: 'PS Staff Representative',
    icon: 'user-check',
    role: 'PS_Staff_Representative',
    subItems: [
      {
        label: 'Election',
        link: '/election/voting',
        role: '',
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: '',
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'SGB Handover',
        link: '/handover/new',
        role: ''
      }

    ]
  },
  {
    label: 'RCL Representative',
    icon: 'user-check',
    role: 'RCL_Representative',
    subItems: [
      {
        label: 'Election',
        link: '/election/voting',
        role: '',
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: '',
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'SGB Handover',
        link: '/handover/new',
        role: ''
      }

    ]
  },
  {
    label: 'Organisations for LSEN schools',
    icon: 'user-check',
    role: 'Organisations_for_LSEN_schools',
    subItems: [
      {
        label: 'Election',
        link: '/election/voting',
        role: '',
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: '',
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'SGB Handover',
        link: '/handover/new',
        role: ''
      }

    ]
  },
  {
    label: 'Educator Representative',
    icon: 'user-check',
    role: 'Educator_Representative',
    subItems: [
      {
        label: 'Election',
        link: '/election/voting',
        role: '',
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: '',
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'SGB Handover',
        link: '/handover/new',
        role: ''
      }

    ]
  },


  {
    label: 'DEO',
    icon: 'globe',
    role: 'DEO',
    link: '/dashboard',
    subItems: [
      {
        label: 'Advocacy Material',
        link: '/advocacy-material/list',
        role: ''
      },
      {
        label: 'Blacklisted Parents',
        link: '/blacklist/schools',
        role: ''
      },
      {
        label: 'Create Weekly Report',
        link: '/election/district-weekly-report',
        role: 'DEO'
      },
      {
        label: 'Disputes',
        link: '/disputes/schools',
        role: 'DEO',
      },
      {
        label: 'Election Memo',
        link: '/memo/election-memo',
        role: ''
      },
      {
        label: 'Election Monitoring Tool',
        link: '/election/schools',
        role: 'DEO'
      },
      {
        label: 'Election Preparation Report',
        link: '/election-preparation/elections',
        role: ''
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Handover Reports',
        link: '/handover/school',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Meetings',
        link: '/meeting/meeting-invitation',
        role: ''
      },
      {
        label: 'Nomination List',
        link: '/nominations/schools',
        role: ''
      },
      {
        label: 'Office Bearers',
        link: '/office-bearer/districts',
        role: ''
      },
      {
        label: 'Outgoing/Incoming checklist',
        link: 'handover/outIncomeDistrict',
        role: ''
      },
      /*{
        label: 'Queries',
        link: '/election/queries',
        role: ''
      },*/
      {
        label: 'Schedule Election',
        link: '/schedule/election',
        role: ''
      },
      {
        label: 'Training',
        link: '/course/training',
        role: 'DEO'
      },
      {
        label: 'Users',
        link: '/users/new-user',
        role: ''
      },
      {
        label: 'View Daily Report',
        link: '/election/daily-report',
        role: ''
      },
      // {
      //   label: 'View Elections Results',
      //   link: '/election/results',
      //   role: ''
      // },
      {
        label: 'View Scheduled Elections',
        link: '/schedule/scheduled-elections',
        role: ''
      },
      {
        label: 'View Weekly Report',
        link: '/election/weekly-deo-report',
        role: 'DEO'
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }

    ]
  },
  {
    label: 'District User',
    icon: 'globe',
    role: 'DISTRICT_USER',
    subItems: [
      // {
      //   label: 'Advocacy Material',
      //   link: '/advocacy-material/list',
      //   role: ''
      // },
      {
        label: 'Blacklisted Parents',
        link: '/blacklist/schools',
        role: ''
      },
      // {
      //   label: 'Create Weekly Report',
      //   link: '/election/district-weekly-report',
      //   role: 'DEO'
      // },
      {
        label: 'Disputes',
        link: '/disputes/schools',
        role: 'DEO',
      },
      {
        label: 'Election Memo',
        link: '/memo/election-memo',
        role: ''
      },
      {
        label: 'Election Monitoring Tool',
        link: '/election/schools',
        role: 'DEO'
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Handover Reports',
        link: '/handover/list',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'MEC’s Pronouncement',
        link: '/meeting/mec-invitation',
        role: ''
      },
      {
        label: 'Meetings',
        link: '/meeting/meeting-invitation',
        role: ''
      },
      {
        label: 'Nomination List',
        link: '/nominations/schools',
        role: ''
      },
      {
        label: 'Office Bearers',
        link: '/office-bearer/districts',
        role: ''
      },
      {
        label: 'Queries',
        link: '/election/school-queries',
        role: ''
      },
      // {
      //   label: 'Schedule Election',
      //   link: '/schedule/election',
      //   role: ''
      // },
      // {
      //   label: 'Schedule Training',
      //   link: '/course/training',
      //   role: ''
      // },
      // {
      //   label: 'SGB Election Training',
      //   link: '/course/scheduled-training',
      //   role: ''
      // },
      {
        label: 'Training',
        link: '/course/training',
        role: 'DEO'
      },
      // {
      //   label: 'Users',
      //   link: '/users/new-user',
      //   role: ''
      // },
      {
        label: 'View Daily Report',
        link: '/election/daily-report',
        role: ''
      },
      {
        label: 'View Scheduled Elections',
        link: '/schedule/scheduled-elections',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }

    ]
  },
  {
    label: 'District Observer',
    icon: 'globe',
    role: 'DISTRICT_OBS',
    subItems: [
      // {
      //   label: 'Complete Observer Form',
      //   link: '/election/complete-observer-form',
      //   role: ''
      // },
      {
        label: 'Disputes',
        link: '/disputes/schools',
        role: '',
      },
      {
        label: 'Election Memo',
        link: '/memo/election-memo',
        role: ''
      },
      {
        label: 'Election Monitoring Tool',
        link: '/election/schools',
        role: ''
      },
      {
        label: 'Election Preparation Report',
        link: '/election-preparation/elections',
        role: ''
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Handover Reports',
        link: '/handover/list',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Nomination List',
        link: '/nominations/schools',
        role: ''
      },
      {
        label: 'View Scheduled Elections',
        link: '/schedule/scheduled-elections',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }
    ]
  },
  {
    label: 'District Electoral Team',
    icon: 'globe',
    role: 'DISTRICT_ELECTORAL_TEAM',
    subItems: [
      {
        label: 'Disputes',
        link: '/disputes/schools',
        role: '',
      },
      {
        label: 'Election Memo',
        link: '/memo/election-memo',
        role: ''
      },
      {
        label: 'Election Monitoring Tool',
        link: '/election/schools',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Handover Reports',
        link: '/handover/list',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Nomination List',
        link: '/nominations/schools',
        role: ''
      },
      // {
      //   label: 'Queries',
      //   link: '/election/queries',
      //   role: ''
      // },
      {
        label: 'View Scheduled Elections',
        link: '/schedule/scheduled-elections',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }
    ]
  },
  {
    label: 'District Director',
    icon: 'globe',
    role: 'DISTRICT_DIRECTOR',
    subItems: [
      {
        label: 'Blacklisted Parents',
        link: '/blacklist/schools',
        role: ''
      },
      {
        label: 'Disputes',
        link: '/disputes/schools',
        role: '',
      },
      {
        label: 'Election Memo',
        link: '/memo/election-memo',
        role: ''
      },
      {
        label: 'Election Monitoring Tool',
        link: '/election/schools',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Handover Reports',
        link: '/handover/list',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Nomination List',
        link: '/nominations/schools',
        role: ''
      },
      {
        label: 'Queries',
        link: '/election/queries',
        role: ''
      },
      {
        label: 'View Scheduled Elections',
        link: '/schedule/scheduled-elections',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }
    ]
  },
  {
    label: 'District Monitor',
    icon: 'globe',
    role: 'DISTRICT_MONITOR',
    subItems: [
      {
        label: 'Disputes',
        link: '/disputes/schools',
        role: '',
      },
      {
        label: 'Election Memo',
        link: '/memo/election-memo',
        role: ''
      },
      {
        label: 'Election Monitoring Tools',
        link: '/election/monitor-view',
        role: ''
      },
      // {
      //   label: 'Election Monitoring Tool',
      //   link: '/election/schools',
      //   role: 'DEO'
      // },
      {
        label: 'Election Preparation Report',
        link: '/election-preparation/elections',
        role: ''
      },
      // {
      //   label: 'Election Overview',
      //   link: '/election-overview',
      //   role: ''
      // },
      {
        label: 'Election Results',
        link: '/election/results',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Handover Reports',
        link: '/handover/list',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Nomination List',
        link: '/nominations/schools',
        role: ''
      },
      {
        label: 'View Scheduled Elections',
        link: '/schedule/assigned-monitor',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }
      // {
      //   label: 'View Elections',
      //   link: '/election/schools',
      //   role:  '
      // },
      // {
      //   label: 'View Scheduled Elections',
      //   link: '/schedule/scheduled-elections',
      //   role: ''
      // }
    ]
  },
  /*   {
      label: 'Elections',
      icon: 'check-square',
      subItems: [
        {
          label: 'Results',
          link: '/election/results',
        },
        {
          label: 'New',
          link: '/election/new'
        },
      ]
    }, */
  {
    label: 'SEO',
    icon: 'book-open',
    role: 'SEO',
    subItems: [
      {
        label: 'Advocacy Material',
        link: '/advocacy-material/view',
        role: ''
      },
      {
        label: 'Blacklisted Parents',
        link: '/blacklist/view',
        role: ''
      },
      // {
      //   label: 'Capture Election Results',
      //   link: '/election/new',
      //   role: ''
      // },
      {
        label: 'Disputes',
        link: '/disputes/list',
        role: ''
      },
      {
        label: 'Election Memo',
        link: '/memo/election-memo',
        role: ''
      },
      {
        label: 'Election Monitoring Tool',
        link: '/election/monitor-view',
        role: ''
      },
      {
        label: 'Election Overview',
        link: '/election-overview',
        role: ''
      },
      {
        label: 'Election Preparation Report',
        link: '/election-preparation/election',
        role: ''
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Handover Reports',
        link: '/handover/list',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Meetings',
        link: '/meeting/meeting-invitation',
        role: ''
      },
      {
        label: 'Nomination List',
        link: '/nominations/nomination-list',
        role: ''
      },
      {
        label: 'Outgoing/Incoming checklist',
        link: 'handover/new',
        role: ''
      },
      /*{
        label: 'Queries',
        link: '/election/queries',
        role: ''
      },*/
      {
        label: 'Training',
        link: '/course/training',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }
      // {
      //   label: 'Users',
      //   link: '/users/new-user',
      //   role: ''
      // },
      // {
      //   label: 'View Election Monitoring Tool',
      //   link: '/election/monitor-view',
      //   role: ''
      // }
      // {
      //   label: 'View Election Results',
      //   link: '/election/results',
      //   role: ''
      // }
    ]
  },
  {
    label: 'School Task Team',
    icon: 'book-open',
    role: 'SCHOOL_TASK_TEAM',
    subItems: [
      {
        label: 'Disputes',
        link: '/disputes/list',
        role: ''
      },
      {
        label: 'Election Memo',
        link: '/memo/election-memo',
        role: ''
      },
      {
        label: 'Election Monitoring Tool',
        link: '/election/monitor-view',
        role: ''
      },
      {
        label: 'Election Overview',
        link: '/election-overview',
        role: ''
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Handover Reports',
        link: '/handover/list',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Meetings',
        link: '/meeting/meeting-invitation',
        role: ''
      },
      {
        label: 'Nomination List',
        link: '/nominations/nomination-list',
        role: ''
      },
      /*{
        label: 'Queries',
        link: '/election/queries',
        role: ''
      },*/
      {
        label: 'Training',
        link: '/course/training',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }
    ]
  },
  {
    label: 'OFFICE BEARERS',
    icon: 'briefcase',
    role: 'OFFICE_BEARER',
    subItems: [
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      }

    ]
  },
  {
    label: 'Principal',
    icon: 'flag',
    role: 'PRINCIPAL',
    subItems: [
      {
        label: 'Add Parent',
        link: '/users/new-user',
        role: ''
      },
      // {
      //   label: 'Appeals',
      //   link: '/disputes/appeals',
      //   role: '',
      // },
      {
        label: 'Advocacy Material',
        link: '/advocacy-material/view',
        role: ''
      },
      {
        label: 'Blacklisted Parents',
        link: '/blacklist/view',
        role: ''
      },
      {
        label: 'Disputes',
        link: '/disputes/list',
        role: ''
      },
      {
        label: 'Election Memo',
        link: '/memo/election-memo',
        role: ''
      },
      {
        label: 'Election Overview',
        link: '/election-overview',
        role: ''
      },
      {
        label: 'Election Preparation Report',
        link: '/election-preparation/election',
        role: ''
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Handover Reports',
        link: '/handover/list',
        role: '',
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Meetings',
        link: '/meeting/meeting-invitation',
        role: ''
      },
      {
        label: 'Nomination List',
        link: '/nominations/nomination-list',
        role: ''
      },
      {
        label: 'Office Bearers',
        link: '/office-bearer',
        role: ''
      },
      {
        label: 'Outgoing/Incoming checklist',
        link: '/handover/new',
        role: '',
      },
      {
        label: 'Queries',
        link: '/election/queries',
        role: ''
      },
      {
        label: 'School Handover',
        link: '/handover/upload',
        role: ''
      },
      {
        label: 'Training',
        link: '/course/training',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }


    ]
  },
  {
    label: 'Learner',
    icon: 'flag',
    role: 'LEARNER',
    subItems: [
      {
        label: 'Election Overview',
        link: '/election-overview',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      // {
      //   label: 'Handover Reports',
      //   link: '/handover/list',
      //   role: '',
      // },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Outgoing/Incoming checklist',
        link: '/handover/new',
        role: '',
      },
      // {
      //   label: 'Meetings',
      //   link: '/meeting/meeting-invitation',
      //   role: ''
      // },
      // {
      //   label: 'School Handover',
      //   link: '/handover/upload',
      //   role: ''
      // },
      // {
      //   label: 'Training',
      //   link: '/course/training',
      //   role: ''
      // }


    ]
  },
  {
    label: 'Teacher',
    icon: 'flag',
    role: 'TEACHER',
    subItems: [
      {
        label: 'Election Overview',
        link: '/election-overview',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      // {
      //   label: 'Handover Reports',
      //   link: '/handover/list',
      //   role: '',
      // },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }
      // {
      //   label: 'Meetings',
      //   link: '/meeting/meeting-invitation',
      //   role: ''
      // },
      // {
      //   label: 'School Handover',
      //   link: '/handover/upload',
      //   role: ''
      // },
      // {
      //   label: 'Training',
      //   link: '/course/training',
      //   role: ''
      // }


    ]
  },
  {
    label: 'Head Office',
    icon: 'users',
    role: 'HOD',
    subItems: [
      {
        label: 'BlackList Parent',
        link: '/blacklist/list',
        role: ''
      },
      {
        label: 'Disputes',
        link: '/disputes/districts',
        role: ''
      },
      {
        label: 'Election Memo',
        link: '/memo/districts',
        role: ''
      },
      {
        label: 'Election Monitoring Tool',
        link: '/election/districts',
        role: ''
      },
      {
        label: 'Election Preparation Report',
        link: '/election-preparation/districts',
        role: ''
      },
      {
        label: 'Election Setup',
        link: '/election/quota',
        role: ''
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Handover Checklist Admin',
        link: '/administration/survey',
        role: ''
      },
      {
        label: 'Handover Document Admin',
        link: '/administration/handoverDocumentAdministration',
        role: ''
      },
      {
        label: 'Handover Reports',
        link: '/handover/province',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Meetings',
        link: '/meeting/meeting-invitation',
        role: ''
      },
      // {
      //   label: 'Nomination List',
      //   link: '/nominations/schools',
      //   role: ''
      // },
      {
        label: 'Office Bearers',
        link: '/office-bearer/districts',
        role: ''
      },
      {
        label: 'Outgoing/Incoming checklist',
        link: 'handover/outIncomeProvince',
        role: ''
      },
      {
        label: 'Queries',
        link: '/election/district-queries',
        role: ''
      },
      {
        label: 'Training',
        link: '/course/training',
        role: ''
      },
      {
        label: 'Users',
        link: '/users/new-user',
        role: ''
      },
      {
        label: 'View Scheduled Elections',
        link: '/schedule/districts',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }
      // {
      //   label: 'View Election Results',
      //   link: '/election/results',
      //   role: ''
      // }

    ]
  },
  {
    label: 'SGB Association',
    icon: 'globe',
    role: 'SGB_ASSOCIATION',
    subItems: [
      {
        label: 'Disputes',
        link: '/disputes/districts',
        role: ''
      },
      {
        label: 'Election Memo',
        link: '/memo/districts',
        role: ''
      },
      {
        label: 'Election Monitoring Tool',
        link: '/election/districts',
        role: ''
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Handover Reports',
        link: '/handover/province',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      // {
      //   label: 'Meetings',
      //   link: '/meeting/meeting-invitation',
      //   role: ''
      // },
      {
        label: 'Nomination List',
        link: '/nominations/schools',
        role: ''
      },
      // {
      //   label: 'Training',
      //   link: '/course/training',
      //   role: ''
      // },
      {
        label: 'View Scheduled Elections',
        link: '/schedule/districts',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }

    ]
  },
  {
    label: 'PEO',
    icon: 'users',
    role: 'PEO',
    subItems: [
      {
        label: 'Blacklisted Parents',
        link: '/blacklist/schools',
        role: ''
      },
      {
        label: 'Disputes',
        link: '/disputes/districts',
        role: ''
      },
      {
        label: 'Election Memo',
        link: '/memo/districts',
        role: ''
      },
      {
        label: 'Election Monitoring Tool',
        link: '/election/districts',
        role: ''
      },
      {
        label: 'Election Preparation Report',
        link: '/election-preparation/districts',
        role: ''
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Handover Reports',
        link: '/handover/province',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Meetings',
        link: '/meeting/meeting-invitation',
        role: ''
      },
      {
        label: 'Nomination List',
        link: '/nominations/schools',
        role: ''
      },
      {
        label: 'Office Bearers',
        link: '/office-bearer/districts',
        role: ''
      },
      {
        label: 'Outgoing/Incoming checklist',
        link: 'handover/outIncomeProvince',
        role: ''
      },
      {
        label: 'Schedule Provincial Monitor',
        link: '/schedule/assign-monitor',
        role: ''
      },
      {
        label: 'Training',
        link: '/course/training',
        role: ''
      },
      {
        label: 'Users',
        link: '/users/new-user',
        role: ''
      },
      {
        label: 'View Scheduled Elections',
        link: '/schedule/districts',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }
    ]
  },
  {
    label: 'Inter Provincial Task Team',
    icon: 'users',
    role: 'INTER_PROVINCIAL_TASK_TEAM',
    subItems: [
      {
        label: 'Disputes',
        link: '/disputes/districts',
        role: ''
      },
      {
        label: 'Election Memo',
        link: '/memo/districts',
        role: ''
      },
      {
        label: 'Election Monitoring Tool',
        link: '/election/districts',
        role: ''
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Handover Reports',
        link: '/handover/province',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Meetings',
        link: '/meeting/meeting-invitation',
        role: ''
      },
      {
        label: 'Nomination List',
        link: '/nominations/schools',
        role: ''
      },
      /*{
        label: 'Queries',
        link: '/election/queries',
        role: ''
      },*/
      {
        label: 'Training',
        link: '/course/training',
        role: ''
      },
      {
        label: 'View Scheduled Elections',
        link: '/schedule/districts',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }
    ]
  },
  {
    label: 'Provincial Monitor',
    icon: 'users',
    role: 'PROVINCIAL_MONITOR',
    subItems: [
      {
        label: 'Disputes',
        link: '/disputes/districts',
        role: ''
      },
      {
        label: 'Election Memo',
        link: '/memo/districts',
        role: ''
      },
      {
        label: 'Election Monitoring Tools',
        link: '/election/monitor-view',
        role: ''
      },
      {
        label: 'Election Preparation Report',
        link: '/election-preparation/districts',
        role: ''
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Handover Reports',
        link: '/handover/province',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Nomination List',
        link: '/nominations/schools',
        role: ''
      },
      {
        label: 'View Scheduled Elections',
        link: '/schedule/assigned-monitor',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }
    ]
  },
  {
    label: 'Observer',
    icon: 'globe',
    role: 'OBSERVER',
    subItems: [
      {
        label: 'Disputes',
        link: '/disputes/districts',
        role: ''
      },
      {
        label: 'Election Memo',
        link: '/memo/districts',
        role: ''
      },
      {
        label: 'Election Monitoring Tool',
        link: '/election/districts',
        role: ''
      },
      {
        label: 'Election Preparation Report',
        link: '/election-preparation/districts',
        role: ''
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      // {
      //   label: 'Handover Reports',
      //   link: '/handover/province',
      //   role: ''
      // },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      {
        label: 'Nomination List',
        link: '/nominations/schools',
        role: ''
      },
      {
        label: 'Schedule Election Observation',
        link: '/schedule/school-assign',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }
      // {
      //   label: 'View Scheduled Elections',
      //   link: '/schedule/districts',
      //   role: ''
      // }
    ]
  },
  {
    label: 'System Admin',
    icon: 'users',
    role: 'ADMIN',
    subItems: [
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'General FAQ',
        link: '/frequently-asked-questions/general',
        role: ''
      },
      {
        label: 'Handover Document Admin',
        link: '/administration/handoverDocumentAdministration',
        role: ''
      },
      /* {
         label: 'Handover Reports',
         link: '/handover/province',
         role: ''
       },*/
      {
        label: 'Handover Survey Admin',
        link: '/administration/survey',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Management Plan',
        link: '/management-plan',
        role: ''
      },
      /* {
         label: 'Outgoing/Incoming checklist',
         link: 'handover/outIncomeProvince',
         role: ''
       },*/
      {
        label: 'Update Landing Page',
        link: '/administration/landing-page',
        role: ''
      },
      {
        label: 'Update Site Content',
        link: '/administration/dropdown',
        role: ''
      },
      {
        label: 'Users',
        link: '/users/new-user',
        role: ''
      }
    ]
  },
  {
    label: 'Parent',
    icon: 'user',
    role: 'PARENT',
    subItems: [
      {
        label: 'Campaign',
        link: 'election/campaign',
        role: ''
      },
      {
        label: 'Disputes',
        link: '/disputes/list',
        role: ''
      },
      {
        label: 'Election',
        link: '/election/voting',
        role: '',
      },
      {
        label: 'Election Results',
        link: '/election/results',
        role: ''
      },
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Legislative Framework',
        link: '/legislative-framework',
        role: ''
      },
      {
        label: 'Nomination',
        link: '/nominations/nominate',
        role: ''
      },
      {
        label: 'Nomination List',
        link: '/nominations/nomination-list',
        role: ''
      },
      {
        label: 'Queries',
        link: '/election/queries',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }

    ]
  },
  {
    label: 'District Officer',
    icon: 'users',
    role: 'DO',
    subItems: [
      {
        label: 'Election FAQ',
        link: '/frequently-asked-questions/election',
        role: ''
      },
      {
        label: 'Meetings',
        link: '/meeting/meeting-invitation',
        role: ''
      },
      {
        label: 'Training',
        link: '/course/training',
        role: ''
      },
      {
        label: 'Voters Roll',
        link: '/nominations/voters-roll',
        role: ''
      }

    ]
  },
  // {
  //   label: 'Legislative Framework',
  //   icon: 'book',
  //   link: '/legislative-framework',
  //   role: 'ADMIN, SEO, DEO, PARENT, PRINCIPAL, PEO, PEM, DISTRICT_MONITOR, DISTRICT_OBS, SGB, HO'
  // },
  // {
  //   label: 'Management Plan',
  //   icon: 'book',
  //   link: '/management-plan',
  //   role: 'ADMIN, SEO, DEO, PARENT, PRINCIPAL, PEO, PEM, DISTRICT_MONITOR, DISTRICT_OBS, SGB, HO'
  // },
  /* {
    label: 'DEO Query',ADMIN, DEO, SEO, PARENT, PEO, PEM
    link: '/queries/district-electoral-officer',
    role: '',
  },
 
  {
    label: 'Email',
    icon: 'mail',
    subItems: [
      {
        label: 'Inbox',
        link: '/apps/email/inbox',
      },
      {
        label: 'Read',
        link: '/apps/email/read'
      },
      {
        label: 'Compose',
        link: '/apps/email/compose'
      },
    ]
  },
  {
    label: 'Chat',
    icon: 'message-square',
    link: '/apps/chat',
  },
  {
    label: 'Calendar',
    icon: 'calendar',
    link: '/apps/calendar',
    badge: {
      variant: 'primary',
      text: 'New',
    }
  },
  {
    label: 'Components',
    isTitle: true
  },
  {
    label: 'UI Kit',
    icon: 'feather',
    subItems: [
      {
        label: 'Alerts',
        link: '/ui-components/alerts',
      },
      {
        label: 'Badges',
        link: '/ui-components/badges',
      },
      {
        label: 'Breadcrumbs',
        link: '/ui-components/breadcrumbs',
      },
      {
        label: 'Buttons',
        link: '/ui-components/buttons',
      },
      {
        label: 'Button group',
        link: '/ui-components/button-group',
      },
      {
        label: 'Cards',
        link: '/ui-components/cards',
      },
      {
        label: 'Carousel',
        link: '/ui-components/carousel',
      },
      {
        label: 'Collapse',
        link: '/ui-components/collapse',
      },
      {
        label: 'Datepicker',
        link: '/ui-components/datepicker',
      },
      {
        label: 'Dropdowns',
        link: '/ui-components/dropdowns',
      },
      {
        label: 'List group',
        link: '/ui-components/list-group',
      },
      {
        label: 'Media object',
        link: '/ui-components/media-object',
      },
      {
        label: 'Modal',
        link: '/ui-components/modal',
      },
      {
        label: 'Navs',
        link: '/ui-components/navs',
      },
      {
        label: 'Navbar',
        link: '/ui-components/navbar',
      },
      {
        label: 'Pagination',
        link: '/ui-components/pagination',
      },
      {
        label: 'Popovers',
        link: '/ui-components/popovers',
      },
      {
        label: 'Progress',
        link: '/ui-components/progress',
      },
      {
        label: 'Rating',
        link: '/ui-components/rating',
      },
      {
        label: 'Scrollbar',
        link: '/ui-components/scrollbar',
      },
      {
        label: 'Spinners',
        link: '/ui-components/spinners',
      },
      {
        label: 'Timepicker',
        link: '/ui-components/timepicker',
      },
      {
        label: 'Tooltips',
        link: '/ui-components/tooltips',
      },
      {
        label: 'Typeadhed',
        link: '/ui-components/typeahead',
      },
    ]
  },
  {
    label: 'Advanced UI',
    icon: 'anchor',
    subItems: [
      {
        label: 'Cropper',
        link: '/advanced-ui/cropper',
      },
      {
        label: 'Owl carousel',
        link: '/advanced-ui/owl-carousel',
      },
      {
        label: 'Sweet alert',
        link: '/advanced-ui/sweet-alert',
      },
    ]
  },
  {
    label: 'Forms',
    icon: 'file-text',
    subItems: [
      {
        label: 'Basic elements',
        link: '/form-elements/basic-elements'
      },
      {
        label: 'Advanced elements',
        subItems: [
          {
            label: 'Form validation',
            link: '/advanced-form-elements/form-validation'
          },
          {
            label: 'Input mask',
            link: '/advanced-form-elements/input-mask'
          },
          {
            label: 'Ng-select',
            link: '/advanced-form-elements/ng-select'
          },
          {
            label: 'Ngx-chips',
            link: '/advanced-form-elements/ngx-chips'
          },
          {
            label: 'Ngx-color-picker',
            link: '/advanced-form-elements/ngx-color-picker'
          },
          {
            label: 'Ngx-dropzone',
            link: '/advanced-form-elements/ngx-dropzone-wrapper'
          },
        ]
      },
      {
        label: 'Editors',
        link: '/form-elements/editors'
      },
      {
        label: 'Wizard',
        link: '/form-elements/wizard'
      },
    ]
  },
  {
    label: 'Charts & graphs',
    icon: 'pie-chart',
    subItems: [
      {
        label: 'ApexCharts',
        link: '/charts-graphs/apexcharts',
      },
      {
        label: 'ChartJs',
        link: '/charts-graphs/chartjs',
      },
    ]
  },
  {
    label: 'Tables',
    icon: 'layout',
    subItems: [
      {
        label: 'Basic tables',
        link: '/tables/basic-table',
      },
      {
        label: 'Data table',
        link: '/tables/data-table',
      },
      {
        label: 'Ngx-datatable',
        link: '/tables/ngx-datatable'
      }
    ]
  },
  {
    label: 'Icons',
    icon: 'smile',
    subItems: [
      {
        label: 'Feather icons',
        link: '/icons/feather-icons',
      },
      {
        label: 'Flag icons',
        link: '/icons/flag-icons',
      },
      {
        label: 'Mdi icons',
        link: '/icons/mdi-icons',
      }
    ]
  },
  {
    label: 'Pages',
    isTitle: true
  },
  {
    label: 'Special pages',
    icon: 'book',
    subItems: [
      {
        label: 'Blank page',
        link: '/general/blank-page',
      },
      {
        label: 'Faq',
        link: '/general/faq',
      },
      {
        label: 'Invoice',
        link: '/general/invoice',
      },
      {
        label: 'Profile',
        link: '/general/profile',
      },
      {
        label: 'Pricing',
        link: '/general/pricing',
      },
      {
        label: 'Timeline',
        link: '/general/timeline',
      }
    ]
  },
  {
    label: 'Authentication',
    icon: 'unlock',
    subItems: [
      {
        label: 'Login',
        link: '/auth/login',
      },
      {
        label: 'Register',
        link: '/auth/register',
      },
    ]
  },
  {
    label: 'Error',
    icon: 'cloud-off',
    subItems: [
      {
        label: '404',
        link: '/error/404',
      },
      {
        label: '500',
        link: '/error/500',
      },
    ]
  },*/
];
