import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AppService {


  constructor(private cookieService: CookieService, private router: Router) { }

  getLoggedInUserRole() {
    return localStorage.getItem("userRole");
  }

  setLoggedInUserRole(value) {
    localStorage.setItem("userRole", value);
  }

  getLoggedInEmisCode() {
    return localStorage.getItem("emisCode");
  }

  setLoggedInEmisCode(value) {
    localStorage.setItem("emisCode", value);
  }

  getLoggedInDistrictCode() {
    return localStorage.getItem("districtCode");
  }

  setLoggedInDistrictCode(value) {
    localStorage.setItem("districtCode", value);
  }


  getAuditControllerName() {
    return localStorage.getItem("controller");
  }

  setAuditControllerName(value) {
    localStorage.setItem("controller", value);
  }


  getAuditRecordID() {
    return localStorage.getItem("recordId");
  }

  setAuditRecordID(value) {
    localStorage.setItem("recordId", value);
  }


  getLoggedInUserId() {
    return localStorage.getItem("userId");
  }

  setLoggedInUserId(value) {
    localStorage.setItem("userId", value);
  }

  getIsLoggedInUser() {

    if (localStorage.getItem("isLoggedIn") == "true")
      return true;
    else {
      localStorage.setItem("isLoggedIn", "false");
      return false;
    }

    //return localStorage.getItem("isLoggedIn");
  }

  getIsLoggedInUserV2() {

    if (localStorage.getItem("isLoggedIn")) {

      return localStorage.getItem("isLoggedIn");
    }
    else {
      return false;
    }

    //return localStorage.getItem("isLoggedIn");
  }

  setIsLoggedInUser(value) {
    localStorage.setItem("isLoggedIn", value);
  }

  getIsLoggedInUsername() {
    return localStorage.getItem("username");
  }

  setIsLoggedInUsername(value) {
    localStorage.setItem("username", value);
  }

  getLoggedInParentId() {
    return localStorage.getItem("parentId");
  }

  setLoggedInParentId(value) {
    localStorage.setItem("parentId", value);
  }

  getUserHasMultipeRoles() {
    return localStorage.getItem("MultipleRoles");
  }

  setUserHasMultipeRoles(value) {
    localStorage.setItem("MultipleRoles", value);
  }

  getUserHasMultipeSchools() {
    return localStorage.getItem("MultipeSchools");
  }

  setUserHasMultipeSchools(value) {
    localStorage.setItem("MultipeSchools", value);
  }

  setUserLoggedInTime(value) {
    localStorage.setItem("logintime", value);
  }

  getUserLoggedInTime() {
    return localStorage.getItem("logintime");
  }

  getLoggedInUserRoleLevel(role) {
    let level = "";

    if (role == "SGB" || role == "SEO" || role == "PRINCIPAL" || role == "PARENT" ||
      role == "OFFICE_BEARER" || role == "LEARNER" || role == "TEACHER" ||
      role == "SGB_CHAIRPERSON" || role == "SCHOOL_TASK_TEAM" || role == "SGB_TREASURER" ||
      role == "SGB_SECRETARY") {
      level = "school";
    } else if (role == "DEO" || role == "DISTRICT_USER" || role == "DISTRICT_MONITOR" ||
      role == "DISTRICT_ELECTORAL_TEAM" || role == "DISTRICT_DIRECTOR") {
      level = "district"
    } else {
      level = "provincial"
    }

    return level;
  }

  setToken(value) {
    localStorage.setItem("jwt", value);
  }

  getToken() {
    // console.log("Token is " + localStorage.getItem("jwt"));
    return localStorage.getItem("jwt");
  }

  setRefreshToken(value) {
    localStorage.setItem("refreshToken", value);
  }
 
    
    

  getRefreshToken() {
    return localStorage.getItem("refreshToken");
  }

  public redirectToLogin() {
    this.router.navigateByUrl('/auth/login');
  }


  setParentSchoolList(value): void {
    if (value) {
      localStorage.setItem("parentSchoolList", value);
    } else {
      localStorage.removeItem("parentSchoolList");
    }
  }

  getParentSchoolList() {
    return localStorage.getItem("parentSchoolList");
  }

}
