// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // base_url: "https://localhost:44324/",
  base_url: "https://sgbelectionapi-qa.azurewebsites.net/",
  homelink: "https://sgbelectiondev.azurewebsites.net",
  doc_upload: "https://sgbserviceapi-dev.azurewebsites.net/api/Upload/Document"
};
