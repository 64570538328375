import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { Roles } from 'src/app/model/role';
import { AuthService } from 'src/app/views/pages/auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, private authService: AuthService, private appService: AppService) {

  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const token = this.appService.getRefreshToken();

    const credentials = JSON.stringify({ refreshToken: token, Id: this.appService.getLoggedInUserId() });
    // console.log('credentials ' + JSON.stringify(credentials));
    // console.log(credentials);
    this.authService.Refresh(credentials).subscribe((res: any) => {

      // console.log('res' + JSON.stringify(res));
      // console.log(res);

      if (res.message !== undefined) {
        // UNCOMMENT HERRE
        // this.appService.setIsLoggedInUser("false");
        // localStorage.clear();
        // this.router.navigateByUrl('/auth/login');
      }

      if (res.token !== undefined) {
        this.appService.setToken(res.token);
      }

      if (res.refreshToken !== undefined) {
        this.appService.setRefreshToken(res.refreshToken);
      }

      // if (res.message !== undefined) {
      //   localStorage.clear();
      //   this.router.navigateByUrl('/auth/login');
      // }


    }, err => {
      console.log("catch error " + err);
    });

    const allowedUserRoles = route.data.userRoles;
    return await this.checkPermission(allowedUserRoles);


  }

  public async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const allowedUserRoles = route.data.userRoles;
    // console.log('roles' + route.data.userRoles);
    return await this.checkPermission(allowedUserRoles);

  }

  private checkPermission(allowedUserRoles: Roles[]): Promise<boolean> {
    return this.authService.getSession().then((session: boolean) => {
      if (session) {
        if (!allowedUserRoles) {
          return true;   // if no user roles has been set, all user are allowed to access the route
        } else {
          return this.authService.getUserRoles().then((userRoles: string[]) => {
            if (this.authService.areUserRolesAllowed(userRoles, allowedUserRoles)) {
              return true;
            } else {

              // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
              this.router.navigateByUrl('/auth/login');
              return false;
            }
          });
        }
      } else {
        this.router.navigateByUrl('/auth/login');
        return false;
      }
    });
  }

}