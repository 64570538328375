import { Component, OnInit, HostListener } from '@angular/core';
import { delay } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { AppService } from './app.service';
import { AuthService } from './views/pages/auth/auth.service';
import Swal from 'sweetalert2';
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router'



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'School Election Backend';
  isLoading: boolean = false;
  public showOverlay = true;

  constructor(
    private _loading: LoadingService,
    private router: Router,
    private authservice: AuthService,
    private appservice: AppService
  ) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    })
  }


  @HostListener('window:beforeunload', ['$event'])

  onWindowClose(event: any): void {
    // Do something

    //  event.preventDefault();
    //  event.returnValue = false;
    // let userid = this.appservice.getLoggedInUserId();

    // if (userid && userid != "null" && userid != "undefined" && userid != "0") {

    //   let data = {
    //     "id": userid
    //   }
    //   this.authservice.Logout(data).subscribe(() => {
    //   })

    // }

    // localStorage.clear();
    // sessionStorage.setItem("isLoggedIn", "false");


  }

  ngOnInit(): void {
    this.listenToLoading();
    let currenttime = new Date();

    if (this.appservice.getUserLoggedInTime() && this.appservice.getUserLoggedInTime() != "null" && this.appservice.getUserLoggedInTime() != "undefined") {

      let loggedin = new Date(this.appservice.getUserLoggedInTime());
      let duration = loggedin.getUTCMinutes() - currenttime.getUTCMinutes();
      let userid = this.appservice.getLoggedInUserId();
      if (duration < 0) {
        duration = duration * -1;
      }
      // console.log("Duration: ", duration);

      // if (duration > 20 && !location.pathname.includes("disclaimer")
      //   && !location.pathname.includes("auth")) {
      //   console.log("logout");


      //   // Swal.fire(
      //   //   'Session Timeout',
      //   //   "Your login session has expired, please login again if you still require to use the system.",
      //   //   'warning'
      //   // )

      //   // localStorage.clear();
      //   let data = {
      //     "id": userid
      //   }

      //   this.authservice.Logout(data).subscribe(() => {
      //   }, err => { console.log(err); })

      //   // this.router.navigate(['/auth/login']);

      //   // this.authservice.getUserById(userid).subscribe((user: any) => {

      //   //   Swal.fire(
      //   //     'Session Timeout',
      //   //     "Your login session has expired, please login again if you still require to use the system.",
      //   //     'warning'
      //   //   )

      //   //   localStorage.clear();
      //   //   let data = {
      //   //     "id": user.id
      //   //   }
      //   //   this.authservice.Logout(data).subscribe(() => {
      //   //   }, err => { console.log(err); })

      //   //   this.router.navigate(['/auth/login']);

      //   // }, err => {
      //   //   console.log(err)
      //   // })

      // }

    }
    // else {
    //   // console.log("logout");
    //   localStorage.clear();
    //   let userid = this.appservice.getLoggedInUserId();
    //   // Swal.fire(
    //   //   'Session Timeout',
    //   //   "Your login session has expired, please login again if you still require to use the system.",
    //   //   'warning'
    //   // )
    //   if (userid && userid != "null" && userid != "undefined") {
    //     let data = {
    //       "id": userid
    //     }
    //     this.authservice.Logout(data).subscribe(() => {
    //     })
    //     // this.authservice.getUserById(userid).subscribe((user: any) => {

    //     //   let data = {
    //     //     "emailAddress": user.emailAddress
    //     //   }
    //     //   this.authservice.Logout(data).subscribe(() => {
    //     //   })

    //     // })

    //   }
    //   if (!location.pathname.includes("disclaimer") && !location.pathname.includes("auth")) {
    //     // this.router.navigate(['/auth/login']);
    //   }
    // }

  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((isLoading) => {
        // console.log(isLoading);
        this.isLoading = isLoading;
      });
  }


  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showOverlay = true;
    }
    if (event instanceof NavigationEnd) {
      this.showOverlay = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }

}
