import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators'
import { LoadingService } from '../loading.service';
import { AppService } from '../app.service';

/**
 * This class is for intercepting http requests. When a request starts, we set the loadingSub property
 * in the LoadingService to true. Once the request completes and we have a response, set the loadingSub
 * property to false. If an error occurs while servicing the request, set the loadingSub property to false.
 * @class {HttpRequestInterceptor}
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  isLoggedIn;


  constructor(
    private _loading: LoadingService, private _appService: AppService,
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this._loading.setLoading(true, request.url);

    this.isLoggedIn = this._appService.getIsLoggedInUser();

    if (this.isLoggedIn == "false" || !this.isLoggedIn) {

      return next.handle(request)
        .pipe(catchError((err) => {
          this._loading.setLoading(false, request.url);
          return err;
        }))
        .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            this._loading.setLoading(false, request.url);
          }
          return evt;
        }));


    } else {

      const newReq = request.clone({
        headers: request.headers.append("Authorization", "Bearer " + this._appService.getToken()).append("UserId", this._appService.getLoggedInUserId()).append("RoleName", this._appService.getLoggedInUserRole()).append("PageAccessed", window.location.href),
      });

      // console.log(newReq);
      return next.handle(newReq).pipe(catchError((err) => {
        this._loading.setLoading(false, request.url);
        return err;
      }))
        .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            this._loading.setLoading(false, request.url);
          }
          return evt;
        }));
    }
  }
}