import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {


  constructor(private http: HttpClient) { }

  Header: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'responseType': 'json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
    'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization'
  });

  base_url = environment.base_url;

  ///////////////get user by id///////////////////////
  getRoleById(id) {
    return this.http.get(this.base_url + "api/User/UserRole?UserId=" + id);
  }

  getParentInfo(id) {
    return this.http.get(this.base_url + "api/ReferenceData/SchoolsByDistrictId?districtCode=" + id)
  }

  checkIfParentDecided(emiscode, parentid) {
    // console.log(this.base_url + "api/Nomination/GetAcceptDeclineNominationsByParent?EmisCode=" + emiscode + "&ParentId=" + parentid)
    return this.http.get(this.base_url + "api/Nomination/GetAcceptDeclineNominationsByParent?EmisCode=" + emiscode + "&ParentId=" + parentid)
  }

  getNominatedParents(emiscode) {
    // console.log(this.base_url + "api/Nomination/GetAcceptDeclineNominationsByParent?EmisCode=" + emiscode + "&ParentId=" + parentid)
    return this.http.get(this.base_url + "api/Parent/GetNominatedParents?EmisCode=" + emiscode)
  }

  getUserById(id) {
    return this.http.get(this.base_url + "api/User/GetById?id=" + id);
  }

  getScheduledNominationByEmisCode(emisCode, date) {
    // console.log(this.base_url + "api/Nomination/GetScheduledNominationByEmisCode?EmisCode=" + emisCode + "&currentDate=" + date)
    return this.http.get(this.base_url + "api/Nomination/GetScheduledNominationByEmisCode?EmisCode=" + emisCode + "&currentDate=" + date)
  }

  getSchoolByParentId(id) {
    return this.http.get(this.base_url + "api/Parent/GetChildrenSchoolByParentId?ParentId=" + id, { headers: this.Header })
  }

  getMeetingTrainingInvites(id) {
    return this.http.get(this.base_url + "api/Meeting/MeetingAttendeeByAttendeeId?UserId=" + id, { headers: this.Header })
  }

  getScheduledMeetingTrainingInvites(id) {
    return this.http.get(this.base_url + "api/Meeting/GetScheduledMeetingAttendeeByAttendeeId?UserId=" + id, { headers: this.Header })
  }

  Logout(data) {
    return this.http.post(this.base_url + "api/Login/Logout", data, { headers: this.Header })
  }

  getElectionPeriod() {
    return this.http.get(this.base_url + "api/ReferenceData/ProvincesList", { headers: this.Header })
  }

  setElectionPeriod(data) {
    return this.http.post(this.base_url + "api/ReferenceData/CreateUpdatePeriod", data, { headers: this.Header })
  }

  getNotificationById(id) {
    return this.http.get(this.base_url + "api/Notification/GetNotifications?userId=" + id);
  }

  getParentUserByIdnumber(idNumber, emiscode) {
    return this.http.get(this.base_url + "api/User/GetParentAccountByIdNumber?IDNumber=" + idNumber + "&EmisCode=" + emiscode, { headers: this.Header });
  }

  AddRegisteredParentToUsers(idNumber, emiscode) {
    return this.http.post(this.base_url + "api/User/AddRegisteredParentToUsers?IDNumber=" + idNumber + "&EmisCode=" + emiscode, { headers: this.Header });
  }


  getSchoolParentInfo(idnumber, emiscode) {
    return this.http.get(this.base_url + "api/Parent/GetParentInfo?IDNumber=" + idnumber + "&EmisCode=700161687" + emiscode, { headers: this.Header });
  }

  getHighestParentID(emiscode) {
    return this.http.get(this.base_url + "api/Parent/GetMaxParentId?EmisCode=" + emiscode, { headers: this.Header });
  }


  assignParentId(idNumber, emiscode, parentid, userid) {
    return this.http.post(this.base_url + "api/Parent/AssignParentId?IDNumber=" + idNumber + "&EmisCode=" + emiscode + "&ParentId=" + parentid + "&UserId=" + userid, { headers: this.Header });
  }
}
