import { Component, Injectable, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { NavbarService } from "../navbar.service";
import { AppService } from 'src/app/app.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2'
import * as moment from 'moment'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbDateStruct, NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[0], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }


  format(date: NgbDateStruct | null): string {
    return date ? (date.day < 10 ? "0" + date.day : date.day) + this.DELIMITER + (date.month < 10 ? "0" + date.month : date.month) + this.DELIMITER + date.year : '';
  }
}
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class NavbarComponent implements AfterViewInit, OnDestroy, OnInit {


  @ViewChild(DataTableDirective, { static: false })

  public isParent: Boolean;
  public parentNominated: Boolean;
  public nominationComplete: Boolean;
  public NominationStarted: Boolean;
  public hasMultipleAccounts: Boolean;
  public hasMultipleSchools: Boolean;
  public invites: any = [];
  public inviteLength: any;
  public allInvites: any;
  public startDateDisplay: any;
  public endDateDisplay: any;
  public startDate: NgbDateStruct;
  public endDate: NgbDateStruct;
  public isFormSubmitted: Boolean;
  public minDate: any;
  public isPassed: Boolean;
  public isUpcoming: Boolean;
  public countDown: any;

  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private calendar: NgbCalendar,
    private router: Router,
    private modalService: NgbModal,
    public formatter: NgbDateParserFormatter,
    private navbarservice: NavbarService,
    private appService: AppService
  ) { }

  public userinfo: any;
  public userID: any;
  public role: any;
  public meetindId: any;

  ngOnInit(): void {
    this.isParent = false;
    this.userinfo = this.appService.getIsLoggedInUsername();
    this.userID = this.appService.getLoggedInUserId();
    this.hasMultipleAccounts = false;
    let emiscode = this.appService.getLoggedInEmisCode();

    if (localStorage.getItem("parentLogin") == "true" ||
      this.appService.getLoggedInUserRole() == "PARENT") {
      this.role = "PARENT";
      this.isParent = true;
      this.appService.setLoggedInUserRole("PARENT");

    }

    this.minDate = {
      year: this.calendar.getToday().year,
      month: this.calendar.getToday().month,
      day: this.calendar.getToday().day
    };

    if (this.appService.getUserHasMultipeSchools() == "true") {
      this.hasMultipleSchools = true;
    }

    if (this.appService.getUserHasMultipeRoles() == "true") {
      this.hasMultipleAccounts = true;
    }

    // this.navbarservice.getNotificationById(this.userID).subscribe((res: any) => {

    // });


    this.navbarservice.getElectionPeriod().subscribe((date: any) => {
      // console.log(date);
      let i = date.length - 1;
      let currentDate = new Date();
      this.startDateDisplay = new Date(date[i].startDate);
      this.endDateDisplay = new Date(date[i].endDate);

      if (currentDate >= this.startDateDisplay) {
        this.isPassed = true;
        this.isUpcoming = false;

        let duration = this.endDateDisplay.getTime() - currentDate.getTime();
        let countdown = moment.duration(duration, 'milliseconds');

        this.countDown = countdown.asDays().toFixed(0);

      } else {
        this.isPassed = false;
        this.isUpcoming = true;

        let duration = this.startDateDisplay.getTime() - currentDate.getTime();
        let countdown = moment.duration(duration, 'milliseconds');

        this.countDown = countdown.asDays().toFixed(0);

      }


    }, err => {
      console.log(err);
      this.startDateDisplay = new Date();
      this.endDateDisplay = new Date();
    })


    if (this.isParent) {
      let currentDate = new Date();
      this.navbarservice.checkIfParentDecided(emiscode, this.appService.getLoggedInParentId()).subscribe((user: any) => {
        // console.log(user);

        if (user) {
          let nominated = user.isNominated;
          let delined = user.hasDecline;

          if (nominated && delined == null) {
            this.parentNominated = true;
          }

        }
      }, err => {
        console.log(err)
      });

      // this.navbarservice.getSchoolByParentId(this.appService.getLoggedInParentId()).subscribe((schools: any) => {
      //   // console.log(schools);
      //   if (schools) {
      //     if (schools.length > 1) {
      //       this.hasMultipleSchools = true;
      //     }
      //   }
      // }, err => {
      //   console.log(err);
      // })

      this.navbarservice.getScheduledNominationByEmisCode(emiscode, moment(currentDate).format("YYYY-MM-DD")).subscribe((election: any) => {
        console.log(election);
        if (election && election.nominationEndDate) {
          let nomination = new Date(election.nominationEndDate);
          if (nomination < currentDate) {
            this.nominationComplete = true;
          }
        }
      }, err => {
        console.log(err);
      })

    }


    //DISTRICT_ELECTORAL_TEAM
    this.navbarservice.getRoleById(this.userID).subscribe((res: any) => {

      console.log(res);
      // if (res.length > 0 && localStorage.getItem("parentLogin") == "true") {

      //   res = res.filter(function (role) {
      //     return role.roleName == "PARENT";
      //   });

      // }

      if (this.appService.getLoggedInUserRole()) {
        this.role = this.appService.getLoggedInUserRole().replace('_', ' ').replace('_', ' ').replace('_', ' ');
      } else {
        this.role = "Not Specified"
      }



      // if (res[0].roleName == "PARENT" || this.role == "PARENT") {
      //   // let date = moment(new Date()).format('YYYY-MM-DD');



      //   if (this.isParent) {
      //     this.navbarservice.checkIfParentDecided(emiscode, this.appService.getLoggedInParentId()).subscribe((user: any) => {
      //       // console.log(user);

      //       if (user) {
      //         let nominated = user.isNominated;
      //         let delined = user.hasDecline;

      //         if (nominated && delined == null) {
      //           this.parentNominated = true;
      //         }

      //       }
      //     }, err => {
      //       console.log(err)
      //     });

      //     // this.navbarservice.getSchoolByParentId(this.appService.getLoggedInParentId()).subscribe((schools: any) => {
      //     //   // console.log(schools);
      //     //   if (schools) {
      //     //     if (schools.length > 1) {
      //     //       this.hasMultipleSchools = true;
      //     //     }
      //     //   }
      //     // }, err => {
      //     //   console.log(err);
      //     // })

      //   }



      // }


      //console.log(JSON.stringify(this.userinfo));


      this.navbarservice.getMeetingTrainingInvites(this.userID).subscribe((invites: any) => {
        let filtered: any;

        // console.log("Invites: ", invites);
        // console.log(invites);

        filtered = invites.filter(function (e) {
          return e.attended == null && new Date() < new Date(e.endDate) && !e.isCancelled;
        });

        this.invites = filtered;
        this.inviteLength = this.invites.length;
        if (this.inviteLength == 1) {
          this.meetindId = filtered[filtered.length - 1].meetingId;
        }

        // console.log(this.invites);
        // if (this.invites.length === 1) {
        //   this.invites = this.invites[0].sort((a,b) => (a.meetingId < b.meetingId) ? 1 : ((b.meetingId < a.meetingId) ? -1 : 0));
        // } else {
        //   this.invites = this.invites.sort((a,b) => (a.meetingId < b.meetingId) ? 1 : ((b.meetingId < a.meetingId) ? -1 : 0));
        // }

      })

    });


    this.dtOptions = {
      pagingType: 'full_numbers',
      order: [[2, 'asc']],
      pageLength: 5
    };

    this.isFormSubmitted = false;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();
    let userid = this.appService.getLoggedInUserId();

    // this.navbarservice.getUserById(userid).subscribe((user: any) => {
    let data
    // if (this.role == "PARENT") {
    //   this.router.navigate(['/auth/parent-login']);
    // } else {
    //   this.router.navigate(['/auth/login']);
    // }

    if (userid && userid != "0" && userid != "null" && userid != "undefined") {

      data = {
        "id": userid
      }

      this.navbarservice.Logout(data).subscribe(() => {
        // localStorage.clear();

        if (this.isParent) {
          // this.router.navigate(['/auth/parent-login']);
          // localStorage.clear();
          this.appService.setIsLoggedInUser("false");
        } else {
          // this.router.navigate(['/auth/login']);
          // localStorage.clear();
          this.appService.setIsLoggedInUser("false");
        }

      }, err => {
        console.log(err);

        if (this.isParent) {
          // this.router.navigate(['/auth/parent-login']);
          // localStorage.clear();
          this.appService.setIsLoggedInUser("false");
        } else {
          // this.router.navigate(['/auth/login']);
          // localStorage.clear();
          this.appService.setIsLoggedInUser("false");
        }
      })


      if (this.isParent || this.role == "PARENT" ||
        localStorage.getItem("parentLogin") == "true") {
        this.router.navigate(['/auth/parent-login']);
        localStorage.clear();
        // this.appService.setIsLoggedInUser("false");
      } else {
        this.router.navigate(['/auth/login']);
        localStorage.clear();
        // this.appService.setIsLoggedInUser("false");
      }

    } else {
      this.router.navigate(['/auth/login']);
      localStorage.clear();
      this.appService.setIsLoggedInUser("false");
    }



    // }, err => {
    //   console.log(err);
    //   if (this.role == "PARENT") {
    //     this.router.navigate(['/auth/parent-login']);
    //   } else {
    //     this.router.navigate(['/auth/login']);
    //   }
    // })


  }

  editProfile() {
    this.router.navigate(['./users/edit-profile']);

  }


  openXlModal(content) {
    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      console.log("Modal closed" + result);
    }).catch((res) => { });
  }


  openModal(content) {
    this.isFormSubmitted = false;

    this.modalService.open(content, { size: 'md' }).result.then((result) => {
      console.log("Modal closed" + result);
    }).catch((res) => { });

    this.startDate = this.convertDate(this.startDateDisplay, "to-object");
    this.endDate = this.convertDate(this.endDateDisplay, "to-object");
  }



  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  isHO() {
    if (this.appService.getLoggedInUserRole() == "HO") {
      return true;
    } else {
      return false;
    }
  }

  savePeriod() {


    if (this.startDate && this.endDate && !this.isInValid(this.startDate, this.endDate)) {

      Swal.fire({
        title: 'Update Election Period?',
        text: 'The election period date will be updated.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {

          Swal.fire(
            'Confirmation!',
            'Election period saved.',
            'success'
          ).then((result) => {
            if (result.value || result.isDismissed) {

              this.startDateDisplay = this.convertDate(this.startDate, "to-date");
              this.endDateDisplay = this.convertDate(this.endDate, "to-date");
              this.modalService.dismissAll();

            }
          })


          // this.navbarservice.setElectionPeriod(this.newElection).subscribe(res => {
          //   console.log(res);



          // }, err => {
          //   console.log(err);
          //   Swal.fire(
          //     'Error!',
          //     'We apologize there was a problem with saving you entry, please try again.',
          //     'error'
          //   )
          // })


        }

      })
    }

    this.isFormSubmitted = true;

  }


  convertDate(date, flag) {

    let newDate;

    if (flag == "to-object") {
      let setDate = new Date(date)
      newDate = {
        year: parseInt(moment(setDate).format("YYYY")),
        month: parseInt(moment(setDate).format("MM")),
        day: parseInt(moment(setDate).format("DD"))
      };

    } else if (flag == "to-date") {
      newDate = new Date(date.year + "-" + date.month + "-" + date.day);
      newDate.setUTCDate(date.day);
      newDate = newDate.toISOString();
    }

    return newDate;

  }

  isInValid(start, end) {

    let invalid = false;

    if (start && end) {
      start = this.convertDate(start, "to-date");
      end = this.convertDate(end, "to-date");

      if (start > end) {
        invalid = true;
      }

    }

    return invalid;

  }


  setNavBarDate(start, end) {

    this.startDateDisplay = start//this.convertDate(this.startDate, "to-date");
    this.endDateDisplay = end//this.convertDate(this.endDate, "to-date");
  }

  showImg() {
    let show = false;


    return show;
  }


  getId() {
    return sessionStorage.getItem("notificationId");
  }

  setId(value) {
    sessionStorage.setItem("notificationId", value);
  }

}
