
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import {Roles} from '../../../core/util/user-roles/user-roles';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Roles } from 'src/app/model/role';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private appService: AppService) { }

  Header: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'responseType': 'json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
    'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization'
  });


  base_url = environment.base_url;
  userId;

  authenticatePersal(jsonData) {
    return this.http.post(this.base_url + "api/Login/AuthenticateOfficial", jsonData, { headers: this.Header })
  }

  authenticateEmployeeByIDNumber(jsonData) {
    return this.http.post(this.base_url + "api/Login/AuthenticateOfficial", jsonData, { headers: this.Header })
  }

  authenticateIDNumber(jsonData) {

    return this.http.post(this.base_url + "api/Login/AuthenticateSGB", jsonData, { headers: this.Header })
  }

  AuthenticateOfficialviaOTP(persal, phonenumber, otp) {
    return this.http.post(this.base_url + "api/Login/AuthenticateOfficialviaOTP?IDNumber=" + persal + "&MobileNumber=" + phonenumber + "&OTP=" + otp, { headers: this.Header })
  }

  AuthenticateOTP(idnumber, phonenumber, otp) {
    // console.log(this.base_url + "api/SMS/AuthenticateOTP?MobileNumber=" + phonenumber + "&OTP=" + otp)
    // userNumber
    // userOTP
    // newPassword
    // console.log(this.base_url + "api/Login/AuthenticateParent?IDNumber=" + idnumber + "&MobileNumber=" + phonenumber + "&OTP=" + otp)

    return this.http.post(this.base_url + "api/Login/AuthenticateParent?IDNumber=" + idnumber + "&MobileNumber=" + phonenumber + "&OTP=" + otp, { headers: this.Header })
  }

  sendOTP(IDnumber) {
    return this.http.get(this.base_url + "api/SMS/SendOTP?IDNumber=" + IDnumber)
  }

  sendUserOTP(persalNumber) {
    return this.http.get(this.base_url + "api/SMS/SendUserOTP?Persal=" + persalNumber, { headers: this.Header })
  }

  resendOTP(id) {
    return this.http.get(this.base_url + "ResendOTP?Id=" + id, { headers: this.Header })
  }

  getSchoolByParentId(id) {
    return this.http.get(this.base_url + "api/Parent/GetChildrenSchoolByParentId?ParentId=" + id, { headers: this.Header })
  }

  getScheduledNominationByEmisCode(emisCode, date) {
    // console.log(this.base_url + "api/Nomination/GetScheduledNominationByEmisCode?EmisCode=" + emisCode + "&currentDate=" + date)
    return this.http.get(this.base_url + "api/Nomination/GetScheduledNominationByEmisCode?EmisCode=" + emisCode + "&currentDate=" + date)
  }

  public getSession(): Promise<boolean> {
    const session = this.appService.getIsLoggedInUser();
    return new Promise((resolve, reject) => {
      if (session) {
        return resolve(true);
      } else {
        return reject(false);
      }
    });
  }

  public getUserRoles(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.userId = this.appService.getLoggedInUserId();


      if (this.userId === null)
        return [];
      else {

        this.http.get(`${this.base_url}api/User/UserRole?userId=${this.userId}`)
          .pipe(catchError((error: any, caught: any) => {
            reject(error);
            return caught;
          }),
            map((res: any) => res))
          .subscribe((role: any[]) => {
            console.log(role);
            if (role.length == 1) {
              this.appService.setLoggedInUserRole(role[0].roleName);
            } else if (role.length > 1 && localStorage.getItem("parentLogin") == "true") {

              role = role.filter(function (r) {
                return r.roleName == "PARENT";
              });

              this.appService.setLoggedInUserRole(role[0].roleName);

            }
            resolve(role);
          }, err => {
            console.log(err);
            return [];
          });
      }

    });
  }

  public areUserRolesAllowed(userRoles: string[], allowedUserRoles: Roles[]): boolean {
    for (const role of userRoles) {
      for (const allowedRole of allowedUserRoles) {
        if (role.toLowerCase() === allowedRole.toLowerCase()) {
          return true;
        }
      }
    }
    return false;
  }

  checkIfParentNominated(id) {
    return this.http.get(this.base_url + "api/User/CheckIfParentNominatedSeconded?UserId=" + id);
  }

  IsParentRegistered(idnumber) {
    return this.http.get(this.base_url + "api/User/IsParentRegistered?IDNumber=" + idnumber);
  }

  AddParentToUsers(idnumber) {
    // console.log(this.base_url + "api/User/AddParentToUsers?IDNumber=" + idnumber)
    return this.http.post(this.base_url + "api/User/AddParentToUsers?IDNumber=" + idnumber, { headers: this.Header });
  }


  getUserRoleById(id) {
    return this.http.get(this.base_url + "api/User/UserRole?userId=" + id, { headers: this.Header });
  }

  getUserById(id) {
    return this.http.get(this.base_url + "api/User/GetById?id=" + id, { headers: this.Header });

  }

  getSchoolsByDistrict(code) {
    return this.http.get(this.base_url + "api/ReferenceData/SchoolsByDistrictId?DistrictCode=" + code)
  }


  getMonitorsByUserId(id) {
    return this.http.get(this.base_url + "api/Schedule/GetMonitorsByUserId?UserId=" + id);

  }


  // sendResetSMS(IDnumber) {
  //   return this.http.get(this.base_url + "api/ReferenceData/SchoolsByDistrictId?DistrictCode=ES" + IDnumber)
  // }

  sendResetMail(username, password, id) {
    // console.log(this.base_url + "api/Login/AuthenticateParent?IDNumber=" + idnumber + "&MobileNumber=" + phonenumber + "&OTP=" + otp)
    return this.http.post(this.base_url + "api/Mail/SendResetMail?Hostname=" + window.location.origin + "&UserName=" + username + "&Pass=" + password + "&Id=" + id, { headers: this.Header })
  }

  SendMessage(cellnumber, message) {
    return this.http.post(this.base_url + "api/SMS/SendMessage?MobileNumber=" + cellnumber + "&Message=" + encodeURIComponent(message), { headers: this.Header })
  }


  SendGenericNotification(subject, greeting, body, title, data) {
    return this.http.post(this.base_url + "api/Mail/SendGenericNotification?EmailSubject=" + subject + "&EmailGreeting=" + greeting + "&EmailBody=" + body + "&EmailTitle=" + title, data, { headers: this.Header })
  }


  Authenticate(data) {
    return this.http.post(this.base_url + "api/Login/Authenticate", data, { headers: this.Header })
  }

  Refresh(data) {
    return this.http.post(this.base_url + "api/Login/Refresh", data, { headers: this.Header })
  }

  Logout(data) {
    return this.http.post(this.base_url + "api/Login/Logout", data, { headers: this.Header })
  }

  getSGBByIDNumber(Id) {
    return this.http.get(this.base_url + "api/User/getSGBByIDNumber?Id=" + Id);
  }


  AuthenticateOfficialOTP(idnumber, phonenumber, otp) {
    return this.http.post(this.base_url + "api/Login/AuthenticateParent?IDNumber=" + idnumber + "&MobileNumber=" + phonenumber + "&OTP=" + otp, { headers: this.Header })
  }

  getSchoolByEmisNumber(school) {
    return this.http.get(this.base_url + "api/ReferenceData/SchoolsByEmisNo?EmisNumber=" + school)
  }

  getHighestParentID(emiscode) {
    return this.http.get(this.base_url + "api/Parent/GetMaxParentId?EmisCode=" + emiscode, { headers: this.Header });
  }


  assignParentId(idNumber, emiscode, parentid, userid) {
    return this.http.post(this.base_url + "api/Parent/AssignParentId?IDNumber=" + idNumber + "&EmisCode=" + emiscode + "&ParentId=" + parentid + "&UserId=" + userid, { headers: this.Header });
  }

  IsParentAccountRegistered(idNumber, emiscode) {
    return this.http.get(this.base_url + "api/User/IsParentAccountRegistered?IDNumber=" + idNumber + "&EmisCode=" + emiscode, { headers: this.Header });
  }

  AddRegisteredParentToUsers(idNumber, emiscode) {
    return this.http.post(this.base_url + "api/User/AddRegisteredParentToUsers?IDNumber=" + idNumber + "&EmisCode=" + emiscode, { headers: this.Header });
  }

  getParentInfo(idnumber, emiscode) {
    return this.http.get(this.base_url + "api/Parent/GetParentInfo?IDNumber=" + idnumber + "&EmisCode=700161687" + emiscode, { headers: this.Header });
  }

  getParentUserByIdnumber(idNumber, emiscode): Observable<any> {
    // console.log(this.base_url + "api/User/GetParentAccountByIdNumber?IDNumber=" + idNumber + "&EmisCode=" + emiscode)
    return this.http.get(this.base_url + "api/User/GetParentAccountByIdNumber?IDNumber=" + idNumber + "&EmisCode=" + emiscode, { headers: this.Header });
  }

  SendSGBUserOTP(IDnumber) {
    return this.http.get(this.base_url + "api/SMS/SendSGBUserOTP?IDNumber=" + IDnumber)
  }

  getUserByIDNumber(IDnumber) {
    return this.http.get(this.base_url + "api/User/GetUserByIDNumber?IDNumber=" + IDnumber)
  }

  getParentSchoolByIDNumber(IDnumber) {
    return this.http.get(this.base_url + "api/Parent/GetParentSchoolListByIDNumber?IDNumber=" + IDnumber)
  }


  getOTPTransactionDate(idnumber, cellnumber) {
    // console.log(this.base_url + "api/User/GetUserActiveTransaction?IDNumber=" + idnumber + "&MobileNumber")
    return this.http.get(this.base_url + "api/User/GetUserActiveTransaction?IDNumber=" + idnumber + "&MobileNumber=" + cellnumber, { headers: this.Header });
  }


  ResetOTP(otp, idNumber, cellnumber) {
    return this.http.post(this.base_url + "api/User/ResetUsedOTP?OTP=" + otp + "&IDNumber=" + idNumber + "&MobileNumber=" + cellnumber, { headers: this.Header });
  }


}
